import React, { useEffect, useState } from 'react';
import { Button, Confirm, Form, Grid, Icon, Label, Popup, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { API, copy, showError, showSuccess, showWarning, timestamp2string } from '../helpers';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import CountUp from 'react-countup';
import { ITEMS_PER_PAGE } from '../constants';
import { renderQuota } from '../helpers/render';
import imgToken from '../images/token.svg';
import imgBlueDollar from '../images/blue_dollar.svg';
import imgGreenDollar from '../images/green_dollar.svg';

const COPY_OPTIONS = [
  { key: 'next', text: 'ChatGPT Next Web', value: 'next' },
  { key: 'ama', text: 'AMA 问天', value: 'ama' },
  { key: 'opencat', text: 'OpenCat', value: 'opencat' }
];

const OPEN_LINK_OPTIONS = [
  { key: 'ama', text: 'AMA 问天', value: 'ama' },
  { key: 'opencat', text: 'OpenCat', value: 'opencat' }
];

function hideMiddlePart(tokenKey) {
  const startLength = 4;
  const endLength = 4;
  const maskedLength = tokenKey.length - startLength - endLength;

  const start = tokenKey.substring(0, startLength);
  const end = tokenKey.substring(tokenKey.length - endLength);
  const masked = '*'.repeat(maskedLength);

  return start + "**************" + end;
}

function renderStatus(status, expired_time, remain_quota) {
  if (expired_time !== -1 && new Date().getTime() > expired_time * 1000) {
    return <Label basic style={{ backgroundColor: '#FFB98E', color: '#7D2F00' }}> 已过期 </Label>;
  }

  if (remain_quota > 0 && status === 4) {
    status = 2;
  }

  switch (status) {
    case 1:
      return <Label basic style={{ backgroundColor: '#A7EFB8', color: '#005955' }}> 已启用 </Label>;
    case 2:
      return <Label basic style={{ backgroundColor: '#FF818B', color: '#700009' }}> 已禁用 </Label>;
    case 3:
      return <Label basic style={{ backgroundColor: '#FFB98E', color: '#7D2F00' }}> 已过期 </Label>;
    case 4:
      return <Label basic style={{ backgroundColor: '#d1cfd1', color: '#393538' }}> 已耗尽 </Label>;
    default:
      return <Label basic style={{ backgroundColor: '#D0C3FB', color: '#290B92' }}> 未知状态 </Label>;
  }
}

function exportRenderStatus(status, expired_time, remain_quota) {
  if (expired_time !== -1 && new Date().getTime() > expired_time * 1000) {
    return '已过期';
  }

  if (remain_quota > 0 && status === 4) {
    status = 2;
  }

  switch (status) {
    case 1:
      return '已启用';
    case 2:
      return '已禁用';
    case 4:
      return '已耗尽';
    default:
      return '未知状态';
  }
}

function renderTimestamp(timestamp) {
  return timestamp2string(timestamp);
}

function MobileCard({ token, idx, manageToken, onCopy }) {
  return (
    <div className='card'>
      <div><strong>令牌名称 :</strong> <span className='small-text'>{token.name ? token.name : '无'}</span></div>
      <br />
      <div><strong>令牌密钥 :</strong> <span className='small-text'>{'sk-' + hideMiddlePart(token.key)}</span></div>
      <br />
      <div><strong>令牌状态 :</strong> <span
        className='small-text'>{renderStatus(token.status, token.expired_time, token.remain_quota)}</span></div>
      <br />
      <div><strong>已用额度 :</strong> <span className='small-text'>{renderQuota(token.used_quota)}</span></div>
      <br />
      <div><strong>剩余额度 :</strong> <span
        className='small-text'>{token.unlimited_quota ? '无限制' : renderQuota(token.remain_quota, 2)}</span></div>
      <br />
      <div><strong>创建时间 :</strong> <span className='small-text'>{renderTimestamp(token.created_time)}</span></div>
      <br />
      <div><strong>过期时间 :</strong> <span
        className='small-text'>{token.expired_time === -1 ? '永不过期' : renderTimestamp(token.expired_time)}</span>
      </div>
      <br />
      <div>
        <Button.Group size={'small'}>
          <Popup
            content='复制'
            trigger={
              <Button
                className='colorless'
                size={'small'}
                basic
                onClick={async () => {
                  await onCopy('', token.key);
                }}
                icon='copy'
              />
            }
          />
        </Button.Group>
        {' '}
        <Popup
          content='删除'
          trigger={
            <Button
              className='colorless'
              size='small'
              basic
              icon='trash alternate'
              onClick={() => {
                if (window.confirm('你确定要删除这个令牌吗?')) {
                  manageToken(token.id, 'delete', idx);
                }
              }}
            />
          }
        />
        <Popup
          content={token.status === 1 ? '禁用' : '启用'}
          trigger={
            <Button
              className='colorless'
              size={'small'}
              basic
              onClick={() => {
                if (token.expired_time !== -1 && new Date().getTime() > token.expired_time * 1000) {
                  return;
                }
                manageToken(
                  token.id,
                  token.status === 1 ? 'disable' : 'enable',
                  idx
                );
              }}
              disabled={token.expired_time !== -1 && new Date().getTime() > token.expired_time * 1000}
              icon={token.status === 1 ? 'ban' : 'check'}
            />
          }
        />
        <Popup
          content='编辑'
          trigger={
            <Button
              className='colorless'
              size={'small'}
              basic
              as={Link}
              to={'/token/edit/' + token.id}
              icon='edit'
            />
          }
        />
      </div>
    </div>
  );
}

const TokensTable = () => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searching, setSearching] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentToken, setCurrentToken] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [targetTokenIdx, setTargetTokenIdx] = useState(0);

  const handleOpenConfirm = (token, idx) => {
    setCurrentToken(token);
    setCurrentIndex(idx);
    setOpen(true);
  };

  const handleConfirm = () => {
    manageToken(currentToken.id, 'delete', currentIndex);
    setOpen(false);
  };

  const loadTokens = async (startIdx) => {
    try {
      const res = await API.get(`/api/token/?p=${startIdx}`);
      const { success, message, data } = res.data;
      if (success) {
        if (startIdx === 0) {
          setTokens(data);
        } else {
          let newTokens = [...tokens];
          newTokens.splice(startIdx * ITEMS_PER_PAGE, data.length, ...data);
          setTokens(newTokens);
        }
      } else {
        showError(message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Failed to load tokens: ', error);
    }
  };

  const onPaginationChange = (e, { activePage }) => {
    (async () => {
      if (activePage === Math.ceil(tokens.length / ITEMS_PER_PAGE) + 1) {
        // In this case we have to load more data and then append them.
        await loadTokens(activePage - 1);
      }
      setActivePage(activePage);
    })();
  };

  const refresh = async () => {
    setLoading(true);
    await loadTokens(activePage - 1);
  };

  const onCopy = async (type, key) => {
    let status = localStorage.getItem('status');
    let serverAddress = '';
    if (status) {
      status = JSON.parse(status);
      serverAddress = status.server_address;
    }
    if (serverAddress === '') {
      serverAddress = window.location.origin;
    }
    let encodedServerAddress = encodeURIComponent(serverAddress);
    const nextLink = localStorage.getItem('chat_link');
    let nextUrl;

    if (nextLink) {
      nextUrl = nextLink + `/#/?settings={"key":"sk-${key}","url":"${serverAddress}"}`;
    } else {
      nextUrl = `https://chat.oneapi.pro/#/?settings={"key":"sk-${key}","url":"${serverAddress}"}`;
    }

    let url;
    switch (type) {
      case 'ama':
        url = `ama://set-api-key?server=${encodedServerAddress}&key=sk-${key}`;
        break;
      case 'opencat':
        url = `opencat://team/join?domain=${encodedServerAddress}&token=sk-${key}`;
        break;
      case 'next':
        url = nextUrl;
        break;
      default:
        url = `sk-${key}`;
    }
    if (await copy(url)) {
      showSuccess('已复制到剪贴板！');
    } else {
      showWarning('无法复制到剪贴板，请手动复制，已将令牌密钥填入搜索框');
      setSearchKeyword(url);
    }
  };

  const onOpenLink = async (type, key) => {
    let status = localStorage.getItem('status');
    let serverAddress = '';
    if (status) {
      status = JSON.parse(status);
      serverAddress = status.server_address;
    }
    if (serverAddress === '') {
      serverAddress = window.location.origin;
    }
    let encodedServerAddress = encodeURIComponent(serverAddress);
    const chatLink = localStorage.getItem('chat_link');
    let defaultUrl;

    if (chatLink) {
      defaultUrl = chatLink + `/#/?settings={"key":"sk-${key}","url":"${serverAddress}"}`;
    } else {
      defaultUrl = `https://chat.oneapi.pro/#/?settings={"key":"sk-${key}","url":"${serverAddress}"}`;
    }
    let url;
    switch (type) {
      case 'ama':
        url = `ama://set-api-key?server=${encodedServerAddress}&key=sk-${key}`;
        break;

      case 'opencat':
        url = `opencat://team/join?domain=${encodedServerAddress}&token=sk-${key}`;
        break;

      default:
        url = defaultUrl;
    }

    window.open(url, '_blank');
  };

  useEffect(() => {
    loadTokens(0)
      .then()
      .catch((reason) => {
        showError(reason);
      });
  }, []);

  const manageToken = async (id, action, idx) => {
    let data = { id };
    let res;
    switch (action) {
      case 'delete':
        res = await API.delete(`/api/token/${id}/`);
        break;
      case 'enable':
        data.status = 1;
        res = await API.put('/api/token/?status_only=true', data);
        break;
      case 'disable':
        data.status = 2;
        res = await API.put('/api/token/?status_only=true', data);
        break;
    }
    const { success, message } = res.data;
    if (success) {
      showSuccess('操作成功完成！');
      let token = res.data.data;
      let newTokens = [...tokens];
      let realIdx = (activePage - 1) * ITEMS_PER_PAGE + idx;
      if (action === 'delete') {
        newTokens[realIdx].deleted = true;
      } else {
        newTokens[realIdx].status = token.status;
      }
      setTokens(newTokens);
    } else {
      showError(message);
    }
  };

  const searchTokens = async () => {
    if (searchKeyword === '') {
      // if keyword is blank, load files instead.
      await loadTokens(0);
      setActivePage(1);
      return;
    }
    setSearching(true);
    const res = await API.get(`/api/token/search?keyword=${searchKeyword}`);
    const { success, message, data } = res.data;
    if (success) {
      setTokens(data);
      setActivePage(1);
    } else {
      showError(message);
    }
    setSearching(false);
  };

  const handleKeywordChange = async (e, { value }) => {
    setSearchKeyword(value.trim());
  };

  const sortToken = (key) => {
    if (tokens.length === 0) return;
    setLoading(true);
    let sortedTokens = [...tokens];
    sortedTokens.sort((a, b) => {
      return ('' + a[key]).localeCompare(b[key]);
    });
    if (sortedTokens[0].id === tokens[0].id) {
      sortedTokens.reverse();
    }
    setTokens(sortedTokens);
    setLoading(false);
  };

  const exportTokens = () => {
    const csvData = [
      ['令牌名称', '令牌密钥', '令牌状态', '已用额度', '剩余额度', '创建时间', '过期时间']
    ];
    tokens.forEach((token) => {
      if (!token.deleted) {
        csvData.push([
          token.name ? token.name : '无',
          'sk-' + token.key,
          exportRenderStatus(token.status, token.expired_time, token.remain_quota),
          renderQuota(token.used_quota),
          token.unlimited_quota ? '无限制' : renderQuota(token.remain_quota, 2),
          renderTimestamp(token.created_time),
          token.expired_time === -1 ? '永不过期' : renderTimestamp(token.expired_time)
        ]);
      }
    });
    const csvContent = Papa.unparse(csvData, { encoding: 'utf-8' });
    const BOM = '\uFEFF';
    const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'tokens.csv');
  };

  return (
    <>
      <div className='overlay'></div>
      <p style={{ color: 'gray', fontSize: '13px' }}>
        使用 API 访问令牌进行服务鉴权和计费。
        API 访问令牌关系到您的个人利益，请妥善留存，不要与其他人共享，也不要保存在客户端代码中。
      </p>
      <Form onSubmit={searchTokens}>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={8} tablet={8} computer={3}>
              <Form.Input
                icon='search'
                fluid
                iconPosition='left'
                placeholder='搜索令牌名称'
                value={searchKeyword}
                loading={searching}
                onChange={handleKeywordChange}
                style={{ height: '34px' }}
              />
            </Grid.Column>
            <Grid.Column mobile={5} tablet={4} computer={12} textAlign='right'>
              <Button.Group size='small'>
                <Button as={Link} to='/token/add' style={{ backgroundColor: '#3877e5', color: 'white' }}
                        loading={loading}>
                  <Icon name='plus' /> 创建令牌
                </Button>
              </Button.Group>
            </Grid.Column>
            <Grid.Column mobile={1} tablet={4} computer={1} textAlign='right'>
              <Button.Group size='small'>
                <Button onClick={refresh} style={{ backgroundColor: 'transparent' }} loading={loading}>
                  <Icon name='refresh' />
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      <div style={{ margin: '15px 0' }}>
        <div className='desktop'>
          <Table basic compact size='small' style={{ border: 'none' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    sortToken('name');
                  }}
                >
                  令牌名称
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    sortToken('key');
                  }}
                >
                  令牌密钥
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    sortToken('status');
                  }}
                >
                  令牌状态
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    sortToken('used_quota');
                  }}
                >
                  已用额度
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    sortToken('remain_quota');
                  }}
                >
                  剩余额度
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    sortToken('created_time');
                  }}
                >
                  创建时间
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  onClick={() => {
                    sortToken('expired_time');
                  }}
                >
                  过期时间
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ whiteSpace: 'nowrap' }}
                >
                  操作
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {tokens.length === 0 ? (
                <Table.Row>
                  <Table.Cell colSpan='8'>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '20px',
                      marginBottom: '40px'
                    }}>
                      <img src={imgToken} alt='No data' style={{ width: '18%', marginBottom: '15px' }} />
                      <span
                        style={{ fontSize: '1.2em', color: '#3877e5' }}>什么都还没有，快去创建一个令牌开始使用吧！</span>
                      <div style={{ height: '30px' }}></div>
                      <Button.Group size='small'>
                        <Button as={Link} to='/token/add' style={{ backgroundColor: '#3877e5', color: 'white' }}
                                loading={loading}>
                          <Icon name='plus' /> 创建令牌
                        </Button>
                      </Button.Group>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : (
                tokens
                  .slice(
                    (activePage - 1) * ITEMS_PER_PAGE,
                    activePage * ITEMS_PER_PAGE
                  )
                  .map((token, idx) => {
                    if (token.deleted) return <></>;
                    return (
                      <Table.Row key={token.id}>
                        <Table.Cell className='name-cell'>{token.name ? token.name : '无'}</Table.Cell>
                        {/* <Table.Cell className="token-cell">{'sk-' + hideMiddlePart(token.key)}</Table.Cell> */}
                        <Table.Cell className='token-cell token-key'>{'sk-' + hideMiddlePart(token.key)}</Table.Cell>
                        <Table.Cell>{renderStatus(token.status, token.expired_time, token.remain_quota)}</Table.Cell>
                        {/* <Table.Cell>$ {renderQuota(token.used_quota)}</Table.Cell> */}
                        <Table.Cell>
                          <div style={{
                            color: '#3877e5',
                            fontWeight: 'bold',
                            fontSize: '0.8em',
                            display: 'flex'
                          }}>
                            <img src={imgBlueDollar} alt='$' style={{ marginRight: '1px', width: '15px' }} />
                            <CountUp end={renderQuota(token.used_quota)} decimals={2} decimal='.' />
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {/* {token.unlimited_quota ? '无限制' : `$ ${renderQuota(token.remain_quota, 2)}`} */}
                          {token.unlimited_quota ?
                            <span style={{ color: '#339d44' }}>无限制</span> :
                            <div style={{
                              color: token.remain_quota < 0 ? 'red' : '#339d44',
                              fontWeight: 'bold',
                              fontSize: '0.8em',
                              display: 'flex'
                            }}>
                              <img src={imgGreenDollar} alt='$' style={{ marginRight: '1px', width: '15px' }} />
                              <CountUp end={renderQuota(token.remain_quota)} decimals={2} decimal='.' />
                            </div>
                          }
                        </Table.Cell>
                        <Table.Cell>{renderTimestamp(token.created_time)}</Table.Cell>
                        <Table.Cell>{token.expired_time === -1 ? '永不过期' : renderTimestamp(token.expired_time)}</Table.Cell>
                        <Table.Cell>
                          <Button.Group size={'small'}>
                            <Popup
                              content='复制'
                              trigger={
                                <Button
                                  className='colorless'
                                  size={'small'}
                                  basic
                                  onClick={async () => {
                                    await onCopy('', token.key);
                                  }}
                                  icon='copy'
                                />
                              }
                            />
                          </Button.Group>
                          {' '}
                          <Popup
                            content='删除'
                            trigger={
                              <Button
                                className='colorless'
                                size='small'
                                basic
                                icon='trash alternate'
                                onClick={() => handleOpenConfirm(token, idx)}
                              />
                            }
                          />
                          <Confirm
                            open={open}
                            onCancel={() => setOpen(false)}
                            onConfirm={handleConfirm}
                            content='你确定要删除该令牌吗?'
                            className='animated fadeIn confirm-dialog'
                            confirmButton='确定'
                            cancelButton='取消'
                          />
                          <Popup
                            content={token.status === 1 ? '禁用' : '启用'}
                            trigger={
                              <Button
                                className='colorless'
                                size={'small'}
                                basic
                                onClick={() => {
                                  if (token.expired_time !== -1 && new Date().getTime() > token.expired_time * 1000) {
                                    return;
                                  }
                                  manageToken(
                                    token.id,
                                    token.status === 1 ? 'disable' : 'enable',
                                    idx
                                  );
                                }}
                                disabled={token.expired_time !== -1 && new Date().getTime() > token.expired_time * 1000}
                                icon={token.status === 1 ? 'ban' : 'check'}
                              />
                            }
                          />
                          <Popup
                            content='编辑'
                            trigger={
                              <Button
                                className='colorless'
                                size={'small'}
                                basic
                                as={Link}
                                to={'/token/edit/' + token.id}
                                icon='edit'
                              />
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  }))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='8'>
                  <div className='pagination-container'>
                    <div>
                      <button
                        className='icon-button'
                        onClick={() => onPaginationChange(null, { activePage: activePage - 1 })}
                        disabled={activePage === 1}
                      >
                        <Icon name='chevron left' size='large' />
                      </button>
                      <span
                        className='page-number'>{`${activePage} / ${Math.ceil(tokens.length / ITEMS_PER_PAGE) + (tokens.length % ITEMS_PER_PAGE === 0 ? 1 : 0)}`}</span>
                      <button
                        className='icon-button'
                        onClick={() => onPaginationChange(null, { activePage: activePage + 1 })}
                        disabled={activePage === Math.ceil(tokens.length / ITEMS_PER_PAGE) + (tokens.length % ITEMS_PER_PAGE === 0 ? 1 : 0)}
                      >
                        <Icon name='chevron right' size='large' />
                      </button>
                    </div>
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </div>

      <div className='mobile'>
        {tokens.length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan='8'>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '40px',
                marginBottom: '40px'
              }}>
                <img src={imgToken} alt='No data' style={{ width: '50%', marginBottom: '10px' }} />
                <span style={{
                  fontWeight: 'bold',
                  fontSize: '1em',
                  color: '#2185D0'
                }}>什么都还没有，快去创建一个令牌开始使用吧！</span>
                <div style={{ height: '30px' }}></div>
                <Button.Group size='small'>
                  <Button as={Link} to='/token/add' style={{ backgroundColor: '#3877e5', color: 'white' }}
                          loading={loading}>
                    <Icon name='plus' /> 创建令牌
                  </Button>
                </Button.Group>
              </div>
            </Table.Cell>
          </Table.Row>
        ) : (
          tokens
            .slice(
              (activePage - 1) * ITEMS_PER_PAGE,
              activePage * ITEMS_PER_PAGE
            )
            .map((token, idx) => {
              if (token.deleted) return <></>;
              return (
                <MobileCard
                  key={token.id}
                  token={token}
                  idx={idx}
                  manageToken={manageToken}
                  onCopy={onCopy}
                />
              );
            })
        )}
        <div className='pagination-container'>
          <div>
            <button
              className='icon-button'
              onClick={() => onPaginationChange(null, { activePage: activePage - 1 })}
              disabled={activePage === 1}
            >
              <Icon name='chevron left' size='large' />
            </button>
            <span
              className='page-number'>{`${activePage} / ${Math.ceil(tokens.length / ITEMS_PER_PAGE) + (tokens.length % ITEMS_PER_PAGE === 0 ? 1 : 0)}`}</span>
            <button
              className='icon-button'
              onClick={() => onPaginationChange(null, { activePage: activePage + 1 })}
              disabled={activePage === Math.ceil(tokens.length / ITEMS_PER_PAGE) + (tokens.length % ITEMS_PER_PAGE === 0 ? 1 : 0)}
            >
              <Icon name='chevron right' size='large' />
            </button>
          </div>
        </div>
      </div>

      {tokens.length > 0 && (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Button size='small' style={{ backgroundColor: '#3877e5', color: 'white' }} onClick={exportTokens}>
            <Icon name='download' /> 导出令牌信息
          </Button>
        </div>
      )}
    </>
  );
};

export default TokensTable;
